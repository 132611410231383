export const REFERRAL_CODE_KEY = 'referralCode';
export const ROLE_AVALON_ACCESS = 'avalon_access';
export const ERROR_AUTH_FIREBASE = 'Error authenticating with Firebase.';
export const ERROR_AUTH_WRONG_PASSWORD =
    'The email and password combination is incorrect, please try again.';
export const ERROR_NO_ACCOUNT = 'No account associated with this email.';
export const ERROR_USER_NOT_FOUND = 'User not found in the system.';
export const ERROR_NO_AVALON_ACCESS = 'You do not have access to Avalon.';
export const ERROR_UNEXPECTED = 'Unexpected error occurred. Please try again.';
export const ERROR_INCORRECT_CREDENTIALS =
    'Username/Password combination is incorrect';
export const ERROR_LOGIN_ISSUE =
    'Error occurred while logging in. Please try again.';
export const ERROR_NO_AVALON_ACCESS_USER =
    'Your account does not have access to Avalon.';
export const ERROR_RESET_PASSWORD_SUCCESS_BUT_NO_AVALON_ACCESS =
    'Your password has been reset. However, your account does not have access to Avalon.';
export const ERROR_RESET_PASSWORD_ISSUE =
    'Error occurred while resetting password. Please try again.';
export const ERROR_RESET_PASSWORD_LINK_EXPIRED =
    'The reset password link has expired. Please try again.';
export const ERROR_NO_PROVIDER = 'No provider selected.';
